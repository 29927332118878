export const BG_PRIMARY = '#E2DFD6';
export const BG_SECONDARY = '#FFFFFF';
export const BG_SECONDARY_LIGHT = '#dca74f';
export const BG_SECONDARY_DARK = '#86550a';
export const BG_SECONDARY_TRANSPARENT = 'rgb(7 58 40 / 30%)';
export const BG_ALTERNATIVE = '#053A28';
export const TEXT_PRIMARY_LIGHT = BG_SECONDARY;
export const TEXT_PRIMARY = '#000';
export const TEXT_PRIMARY_DARK = TEXT_PRIMARY;
export const TEXT_SECONDARY = TEXT_PRIMARY;
export const BTN_NAVIGATION_COLOR = '#150D05';
export const ICON_NAVIGATION_COLOR = '#FFFFFF';
export const DEFAULT_BUTTON_PROPS = '';
